<template>
  <div class="content flex my-4">
    <div class="auth-form mx-auto my-auto">
      <div class="card">
        <div class="card-body text-center">
          <template v-if="isSuccess">
            <h1 class="heading-bigger text-success mb-4 width-narrow mx-auto">Registration complete</h1>

            <p class="heading-tiny mt-5 width-narrow mx-auto">
              <template v-if="isWithPayment">
                Your payment is beeing processed.
              </template>
              <template v-else>
                Your registration was successful!
              </template>
            </p>
            <p class=" mt-2 mb-4 width-narrow mx-auto">
              You will receive from Us an invitation confirmation e-mail with activation link.<br>
              Please click on that link and confirm Your registration. After that Your account will be active and you'll be able to login to FunFit.
            </p>
          </template>
          <template v-else>
            <h1 class="heading-bigger text-danger mb-4">Something went wrong</h1>
            <p class="heading-tiny my-5 width-narrow mx-auto">
              <template v-if="isWithPayment">
                There might be a problem with Your payment.
              </template>
              <template v-else>
                Your registration was unsuccessful.<br>
              </template>

              Please contact system Administrator.
            </p>
          </template>

          <div class="text-center">
            <f-button
              theme="text"
              @click.native="$router.push('/login')">
              Go to login page
            </f-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'register-result',
  computed: {
    isWithPayment () {
      if (this.$route.query && 'payment_progress' in this.$route.query) {
        return true;
      }

      return false;
    },
    isSuccess () {
      if (this.$route.query && 'payment_progress' in this.$route.query) {
        return this.$route.query.payment_progress;
      }

      if (this.$route.params && 'success' in this.$route.params) {
        return this.$route.params.success;
      }

      return false;
    }
  }
};
</script>
